<script lang="ts">
export default {
  name: 'app-brand-card-news'
}
</script>

<script lang="ts" setup>
const props = defineProps({
  img: {
    type: String
  },
  slug: {
    type: String
  },
  contentClass: {
    type: String
  },
  titulo: {
    type: String
  },
  marca: {
    type: String
  }
});


</script>

<template lang="pug">
nuxt-link(:to="`/${marca}/noticias/${slug}/`")
  article.app-brand-card-news
    header.app-brand-card-news__header
      img.img-fluid(:src="img", alt="")
      .app-brand-card-news__content(:class="contentClass")
        p {{titulo}}
</template>

<style lang="scss">
@import 'index';
</style>